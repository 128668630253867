<template>
  <div>
    <div class="memu-wrap">
      <span class="is-active">管理员管理</span>
    </div>
    <el-card>
      <el-form inline size="small">
        <el-form-item label="管理员名称:">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="管理账号:">
          <el-input v-model="form.account" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getDataList">查询</el-button>
          <el-button @click="rest">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="tableData"
        style="width: 100%;padding: 8px 0 ! important;"
        :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}"
      >
        <el-table-column label="管理名称" prop="name" />
        <el-table-column label="登录账号" prop="account" />
        <el-table-column label="类型">
          <template scope="{row}">
            {{ row.master? '主管理': '子管理' }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template scope="{row}">
            <el-button type="text" @click="openSetPermission(row.id)">权限设置</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        style="padding:20px;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 权限设置 -->
    <set-permission ref="setPermission" />
  </div>
</template>

<script>
import { getAdminAuthorityList } from '@/utils/api.js'
import SetPermission from './SetPermission'
export default {
  components: { SetPermission },
  data() {
    return {
      form: {},
      tableData: [],
      dataListLoading: false,
      current: 1,
      size: 10,
      total: null
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    rest() {
      this.form = {}
      this.getDataList()
    },
    async getDataList() {
      this.dataListLoading = true
      const res = await getAdminAuthorityList(this.form, this.current, this.size)
      this.dataListLoading = false
      this.tableData = res.data
      console.log(res)
      const { code, data } = res
      if (code === 200) {
        this.tableData = data
      }
    },
    // 每页数
    handleSizeChange(val) {
      this.size = val
      this.current = 1
      this.getDataList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.current = val
      this.getDataList()
    },
    openSetPermission(id) {
      this.$refs.setPermission.open(id)
    }
  }
}
</script>

<style lang="less" scoped>
.memu-wrap {
  padding: 16px 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #000;
  span {
    margin-right: 20px;
    padding: 0 25px;
    line-height: 40px;
    display: block;
  }
  .is-active {
    background: rgba(18, 110, 255, 1);
    border-radius: 4px;
    color: #ffffff;
  }
}
</style>
