<template>
  <div>
    <el-dialog :visible.sync="visible" title="权限设置" width="900px">
      <div class="tree-box">
        <div class="tree-head">
          <div class="tree-head-check"><el-checkbox v-model="checkAll" @change="onCheckAllChange" /></div>
          <div class="tree-head-one">权限名称</div>
          <div style="flex: 1;display: flex;">权限内容</div>
        </div>
        <el-tree
          ref="treeTable"
          :data="treeData"
          default-expand-all
          show-checkbox
          node-key="id"
          :props="props"
        >
          <div slot-scope="{ data }" class="tree-custom-node">
            <span>{{ data.introduction }}</span>
            <div style="position: absolute; right: 0; width: 450px;">{{ data.name }}</div>
          </div>
        </el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible=false">取 消</el-button>
        <el-button type="primary" @click="permissionSubmit">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAuthorityList, setAdminAuthority, getAdminAuthorityList } from '@/utils/api'
export default {
  data() {
    return {
      visible: false,
      treeData: [],
      props: {
        label: 'introduction',
        children: 'children'
      },
      checkAll: false,
      adminId: ''
    }
  },
  methods: {
    async getAuthorityList() {
      const res = await getAuthorityList()
      const { data } = res
      this.treeData = data
    },
    async open(id) {
      this.visible = true
      this.adminId = id
      await this.getAuthorityList()
      this.getAdminAuthorityList()
    },
    onCheckAllChange() {
      if (this.checkAll) {
        this.$refs.treeTable.setCheckedNodes(this.treeData)
      } else {
        this.$refs.treeTable.setCheckedNodes([])
      }
    },
    // 权限设置确认提交
    async permissionSubmit() {
      const adminAuthorities = this.$refs.treeTable.getCheckedNodes()
      if (!adminAuthorities || !adminAuthorities.length) {
        this.$message.warning('至少选中一项')
        return
      }
      const opts = {
        adminAuthorities
      }
      const res = await setAdminAuthority(opts)
      const { code } = res
      if (code === 200) {
        this.$message.success('设置成功！')
        this.visible = false
      }
    },
    async getAdminAuthorityList() {
      const opts = {
        adminId: this.adminId
      }
      const res = await getAdminAuthorityList(opts)
    }
  }
}
</script>

<style lang="less" scoped>
  .tree-box {
    width: 100%;
    padding: 0 50px 0 20px;
    .tree-head {
      background-color: #f8f8f8;
      line-height: 40px;
      height: 40px;
      border: 1px solid #dcdee3;
      border-bottom: none;
      display: flex;
      font-size: 14px;
      color: #606266;
      padding-right: 8px;
    }
    .tree-head-check {
      width: 38px;
      text-align: right;
    }
    .tree-head-one {
      flex: 1;
    }
    .tree-head-one {
      padding-left: 8px;
    }
    .tree-custom-node {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding-right: 8px;
      position: relative;
      margin: 50px 0;
    }
    .el-tree {
      border: 1px solid #dcdee3;
      border-top: none;
    }
  }
  .dialog-footer {
    text-align: center;
    margin-top: 20px;
  }
</style>
